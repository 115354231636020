<template>
  <span>
    <v-dialog v-model="dialogIsOpened" :max-width="dialogWidth" class="action-add-new" persistent scrollable>
      <v-card>
        <v-card-title class="text-center justify-center">
          <slot name="title">
            {{ buttonLabel }}
          </slot>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-5">
          <slot v-bind="{ form, errors, creator }" name="fields"></slot>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn :disabled="loading" :loading="loading" text @click="cancel">Anuleaza</v-btn>
          <v-spacer />
          <v-btn :disabled="loading" :loading="loading" color="primary" class="primary-button"
            @click="confirm">Creaza</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <new-button v-bind="attrs" v-on="on" :button-label="buttonLabel" :class="buttonExtraClass" @click="openDialog" />
      </template>
      <span>{{ buttonLabel }}</span>
    </v-tooltip>
  </span>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'
import Table from '@/components/layout/CitDataTable'

export default {
  name: 'ActionAddNew',
  components: { NewButton },
  inject: {
    table: {
      default: {}
    }
  },
  props: {
    actionUrl: {
      type: String,
      required: true
    },
    buttonLabel: {
      type: String,
      default: 'Adauga'
    },
    dialogWidth: {
      type: String,
      default: '350px'
    },
    buttonExtraClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogIsOpened: false,
      form: {},
      errors: {},
      loading: false
    }
  },
  computed: {
    creator() {
      return this
    }
  },
  methods: {
    openDialog() {
      this.dialogIsOpened = true
    },
    cancel() {
      this.$emit('cancel')
      this.form = {}
      this.errors = {}
      this.dialogIsOpened = false
    },
    confirm() {
      this.loading = true
      this.errors = {}
      this.$http.post(this.actionUrl, this.form)
        .then(({ data }) => {
          this.$emit('saved', data)
          this.table.loadItems()
          this.loading = false
          this.cancel()
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.loading = false
        })
    }
  }
}
</script>
