<template>
  <action-edit
    :action-url="`product-brands/${item.id}`"
    :item="item"
    button-label="Editeaza Brand"
  >

    <template v-slot:fields="{form, errors}">

      <div class="mb-4">
        <v-text-field v-model="form.name" label="Nume"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>

      <div class="mb-4">
        <div v-if="form.logo">
          <v-img
            :src="form.logo"
            class="mb-4"
            contain
            max-height="150"
            max-width="500"
          />
        </div>
        <base64-file-uploader
          v-model="form.logo_file"
          label="Logo"
        />
      </div>

    </template>
  </action-edit>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ActionEdit from '@/components/layout/ActionButtons/ActionEdit'
import Base64FileUploader from '@/components/general-form/Base64FileUploader'

export default {
  components: {
    ActionEdit,
    ErrorMessages,
    Base64FileUploader
  },
  props: {
    item: {
      required: true
    }
  }
}

</script>
