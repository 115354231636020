<template>
  <cit-data-table
    :headers="headers"
    resource-path="product-brands"
  >

    <template v-slot:actions>
      <create-form/>
    </template>

    <template v-slot:item.actions="{item}">

      <edit-form :item="item"/>

      <action-delete :action-url="`product-brands/${item.id}`"/>

    </template>

  </cit-data-table>

</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import CreateForm from './Create'
import EditForm from './Edit'
import ActionDelete from '@/components/layout/ActionButtons/ActionDelete'

export default {
  components: {
    ActionDelete,
    CitDataTable,
    CreateForm,
    EditForm
  },
  data () {
    return {
      headers: [
        {
          text: 'Logo',
          value: 'logo',
          width: '100',
          display: 'image',
          sortable: false
        },
        {
          text: 'Denumire',
          value: 'name',
          filterType: 'text',
          sortable: true
        }
      ]
    }
  }
}
</script>
